import * as yup from 'yup'

const schemaReasonForm = yup.object({
  reason: yup.string().required('Alasan wajib diisi.')
})

type TSchemaReasonForm = yup.InferType<typeof schemaReasonForm>

export {
  schemaReasonForm
}

export type {
  TSchemaReasonForm
}
