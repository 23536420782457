
/* eslint-disable vue/no-dupe-keys */
import {
  defineComponent,
  toRefs
} from 'vue'
import type {
  PropType
} from 'vue'
import type {
  TTransPengirimanHdDetail
} from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'
import rupiahFormat from '@/utils/helpers/rupiahFormat'

export default defineComponent({
  name: 'ContentMiisi',
  props: {
    data: {
      type: Object as PropType<TTransPengirimanHdDetail>,
      required: true
    },
  },
  setup(props) {
    const {
      data
    } = toRefs(props)
    return {
      data,
      rupiahFormat
    }
  },
})
