
/* eslint-disable no-unused-expressions */
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  watch,
  defineComponent
} from 'vue'
import {
  deliveryPendingUseCase,
  receiveItemUseCase,
  receiptCheckingUseCase,
  draftItemUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import moment from 'moment'
import {
  useRouter
} from 'vue-router'
import {
  isEmpty,
  isNull
} from 'lodash'
import {
  useForm,
  Field
} from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import {
  schemaReasonForm
} from '@/utils/schema/reason'
import type {
  TSchemaReasonForm
} from '@/utils/schema/reason'
import Button from 'primevue/button'
import DialogConfirm from '@/views/shared-components/dialog/Confirm.vue'
import ContentDefault from './ContentDefault.vue'
import ContentMilsi from './ContentMilsi.vue'

const modules = 'deliveryItem'

export default defineComponent({
  name: 'DialogDetailResiV2',
  components: {
    ContentDefault,
    ContentMilsi,
    DialogConfirm,
    Field,
    Button
  },
  props: {
    isShowDialog: {
      default: false
    },
    isRefund: {
      default: false
    },
    idTrans: {
      default: null
    },
    idJadwal: {
      default: null
    },
    module: {
      type: String,
      default: () => null
    },
    tags: {
      type: String,
      default: () => null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const isFromGuid = ref(false)
    const propertiesProps = reactive(props)
    const idTrans = computed(() => props.idTrans) as any
    const refDialogConfirm = ref<InstanceType<typeof DialogConfirm>>()
    const refDialogCancelPackage = ref<InstanceType<typeof DialogConfirm>>()
    const hidedialogDetailResi = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }
    const dialogDetailResi = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val: any) => {
        // console.log('val =>', val)
        if (!val) {
          hidedialogDetailResi()
        }
      }
    })
    const onRefund = computed(() => propertiesProps.isRefund)
    const data = ref<any>({})
    const isValidData = computed(() => !isEmpty(data.value))
    const isMiisi = computed(() => propertiesProps.tags)
    const submitted = ref(false)
    const resMessage = ref('')
    const dataForm = reactive({
      description: ''
    })
    const rules = {
      description: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      }
    }
    const dataAnsuransi = ref({
      hasAnsuransi: false,
      nama: '',
      noAsuransi: ''
    })
    const generateDataAsuransi = () => {
      if (data.value) {
        data.value.dataAnsuransi = dataAnsuransi
        if (data.value.TransPengirimanDetails.length > 0) {
          if (data.value.TransPengirimanDetails[0].Asuransi) {
            dataAnsuransi.value.hasAnsuransi = true
            dataAnsuransi.value.nama = data.value.TransPengirimanDetails[0].Asuransi.Nama
          }
        }
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const prosesSendScanResi = async () => {
      store.dispatch('showLoading')
      const sendData = {
        Guid: data.value.Guid,
        TransJadwalHdId: propertiesProps.idJadwal
      }
      const response = await receiptCheckingUseCase.sendScanResi(sendData)
      // console.log('response =>', response)
      store.dispatch('hideLoading')
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          summary: `${response.result.title ?? response.result.Title}`,
          detail: `${response.result.detail ?? response.result.Detail} ${response.result.Note}`,
          group: 'bc',
          life: 3000
        })
        emit('hideDialog', response.result.Data)
        emit('reloadData')
      }
    }

    const submitScanResi = () => {
      prosesSendScanResi()
    }

    const dataDetail = async () => {
      const {
        error,
        message,
        result
      } = await receiveItemUseCase.getDetail(idTrans.value)
      if (!error) {
        if (idTrans.value.length === 36) {
          isFromGuid.value = true
        }
        data.value = result
        generateDataAsuransi()
      } else {
        resMessage.value = message
      }
      store.dispatch('hideLoading')
    }

    const storeRefund = async () => {
      store.dispatch('showLoading')
      const response = await deliveryPendingUseCase.refundPengiriman(idTrans.value, {
        'Keterangan Refund': dataForm.description
      })
      store.dispatch('hideLoading')
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        hidedialogDetailResi()
        emit('reloadData')
      }
    }
    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      storeRefund()
    }
    const openManifestJadwal = (isFormValid: boolean) => {
      router.push({
        name: 'transaksi-manifest-pengiriman-detail',
        params: {
          id: data.value.TransJadwalHd.Id
        }
      })
    }

    const onProcessTrans = () => {
      console.log('data', data.value)
      const typeDelivery: string = data.value.TipePengiriman
      const id = data.value.Id
      let type = '' as string
      switch (typeDelivery) {
        case 'AD':
          type = 'agent-to-door'
          break
        case 'AA':
          type = 'agent-to-agent'
          break
        case 'DA':
          type = 'door-to-agent'
          break
        case 'DD':
          type = 'door-to-door'
          break
        default:
          console.log('type not found')
      }
      store.dispatch(`${modules}/setIsDraft`, data.value.Tags === '')
      store.dispatch(`${modules}/setIsFromQr`, !isNull(data.value.Tags))

      router.push({
        name: 'transaksi-pengiriman-barang-edit',
        params: {
          type,
          id
        }
      })
    }

    const {
      errors: errorsFormReason,
      handleSubmit: handleSubmitFormReason,
      resetForm: resetFormReason,
      meta: metaFormReason,
      values: valuesFormReason
    } = useForm<TSchemaReasonForm>({
      validationSchema: toTypedSchema(schemaReasonForm),
      initialValues: {
        reason: ''
      }
    })

    const onCloseDialogCancelPackage = () => {
      resetFormReason()
      refDialogCancelPackage.value?.hide()
    }

    const processCancelPackage = async (values: TSchemaReasonForm) => {
      const {
        error,
        message
      } = await draftItemUseCase.cancelDeliveryDraft(data.value.Id, {
        Reason: values.reason
      })
      if (error) {
        $toast.add({
          severity: 'error',
          detail: message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: 'Berhasil membatalkan paket.',
          group: 'bc',
          life: 3000
        })
        onCloseDialogCancelPackage()
        hidedialogDetailResi()
        emit('reloadData')
      }
    }

    const onSubmitCancel = handleSubmitFormReason((value) => {
      // console.log('values submit', value)
      processCancelPackage(value)
    })

    const onCancelPackage = () => {
      refDialogCancelPackage.value?.show()
    }

    const onNext = () => {
      // eslint-disable-next-line no-unused-expressions
      refDialogConfirm.value?.openModal({
        imgContent: require('@/assets/img/confirmation/package-appropriate.png'),
        btnBackTitle: 'Kembali',
        btnSubmitTitle: 'Barang Sudah Sesuai',
        subTitle: 'Pastikan data barang yang ada di sistem sudah sesuai dengan data barang yang akan dikirimkan!',
        title: '',
        isMiisi: true
      }).then((isConfirm) => {
        if (isConfirm) {
          store.dispatch(`${modules}/setIsMiisi`, !!isMiisi.value)
          onProcessTrans()
        }
      })
    }

    onMounted(() => {
      if (idTrans.value) {
        store.dispatch('showLoading')
        dataDetail()
      }
    })

    return {
      hidedialogDetailResi,
      storeRefund,
      onRefund,
      dialogDetailResi,
      data,
      store,
      app,
      submitData,
      dataForm,
      v$,
      moment,
      submitted,
      dataAnsuransi,
      openManifestJadwal,
      isFromGuid,
      submitScanResi,
      isValidData,
      resMessage,
      onProcessTrans,
      isMiisi,
      onCancelPackage,
      onNext,
      refDialogConfirm,
      refDialogCancelPackage,
      errorsFormReason,
      metaFormReason,
      onSubmitCancel,
      onCloseDialogCancelPackage
    }
  }
})
