import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")
  const _component_DeliveryItem = _resolveComponent("DeliveryItem")
  const _component_DraftItem = _resolveComponent("DraftItem")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock(_component_Card, { class: "card-no-shadow" }, {
    content: _withCtx(() => [
      _createVNode("div", null, [
        _createVNode(_component_TabView, {
          class: "color-primary mb-5",
          activeIndex: _ctx.indexTab,
          "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => (_ctx.indexTab = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listHeaders, (header, key) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                header: header.title,
                key: key
              }, null, 8, ["header"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeIndex"]),
        (_ctx.indexTab === 0)
          ? (_openBlock(), _createBlock(_component_DeliveryItem, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.indexTab === 1)
          ? (_openBlock(), _createBlock(_component_DraftItem, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}