
import { defineComponent, ref } from 'vue'
import DeliveryItem from './DeliveryItem.vue'
import DraftItem from './DraftItem.vue'

export default defineComponent({
  name: 'Index-Delivery-Item',
  components: {
    DeliveryItem,
    DraftItem
  },
  setup() {
    const indexTab = ref(0)
    const listHeaders = ref([{
      key: 0,
      title: 'Buat Baru'
    },
    {
      key: 1,
      title: 'Draft Pengiriman'
    }])

    return {
      listHeaders,
      indexTab
    }
  }
})
