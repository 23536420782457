
/* eslint-disable vue/no-dupe-keys */
import { defineComponent, toRefs } from 'vue'
import type { PropType } from 'vue'
import type {
  TTransPengirimanHdDetail
} from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'
import moment from 'moment'

// type TContentDefaultProps = {
//   data: TTransPengirimanHdDetail,
//   onRefund: boolean
// }

export default defineComponent({
  name: 'ContentDefault',
  props: {
    data: {
      type: Object as PropType<TTransPengirimanHdDetail>,
      required: true
    },
    onRefund: {
      type: Boolean,
      required: true
    },
    dataAnsuransi: {
      type: Object as PropType<{
        nama: string,
        hasAnsuransi: boolean,
        noAsuransi: string
      }>,
      required: true
    }
  },
  emits: ['openManifestJadwal'],
  setup(props, {
    emit
  }) {
    const {
      data,
      onRefund,
      dataAnsuransi
    } = toRefs(props)

    return {
      data,
      onRefund,
      moment,
      emit,
      dataAnsuransi
    }
  }
})
